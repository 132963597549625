import React from 'react';
// @ts-ignore
import { ReactComponent as LogoWTagline } from '../../assets/vectors/LogoWTagline.svg';

const Footer = () => {
    return (
        <footer className="py-12 pb-5 bg-white sm:pb-12 sm:pt-16 lg:pt-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="max-w-2xl mx-auto text-center">
                <h5 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">You are 2 minutes away from saving up to $400 a year.</h5>
    
                <div className="flex justify-center sm:justify-center">
                                <a
                                    href="/compare"
                                    title="Huglo Comparison App"
                                    className="inline-flex justify-center px-6 py-3 mt-8 text-base font-bold text-black transition-all duration-200 bg-customOrange border border-transparent rounded sm:px-8 sm:py-4 sm:text-lg sm:mt-10 font-pj hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-customOrange w-full sm:w-auto"
                                    role="button"
                                >
                                    COMPARE NOW
                                </a>
                            </div>
            </div>
    
            <div className="lg:flex lg:items-center lg:justify-between mt-14 lg:mt-24">
                <div className="flex justify-center lg:justify-start">
                    <LogoWTagline width={250} height={41} />
                </div>
    
                <ul className="flex flex-wrap items-center justify-center mt-8 space-x-3 space-y-2 text-center sm:space-y-0 sm:space-x-6 sm:mt-12 sm:space-x-16 lg:mt-0">
                    <li>
                        <a href="https://www.huglo.com.au/about-us" target="_blank" rel="noopener noreferrer" title="About us" className="text-base sm:text-lg font-medium text-gray-900 transition-all duration-200 font-pj hover:text-gray-600"> About </a>
                    </li>
    
                    <li>
                        <a href="https://www.huglo.com.au/blog" target="_blank" rel="noopener noreferrer" title="Blog" className="text-base sm:text-lg font-medium text-gray-900 transition-all duration-200 font-pj hover:text-gray-600"> Blog </a>
                    </li>
    
                    <li>
                        <a href="https://www.huglo.com.au/reviews" target="_blank" rel="noopener noreferrer" title="Reviews" className="text-base sm:text-lg font-medium text-gray-900 transition-all duration-200 font-pj hover:text-gray-600"> Reviews </a>
                    </li>

                    <li>
                        <a href="/categories" title="Categories" className="text-base sm:text-lg font-medium text-gray-900 transition-all duration-200 font-pj hover:text-gray-600"> Categories </a>
                    </li>
    
                    <li>
                        <a href="https://www.huglo.com.au/contact" target="_blank" rel="noopener noreferrer" title="Contact" className="text-base sm:text-lg font-medium text-gray-900 transition-all duration-200 font-pj hover:text-gray-600"> Contact </a>
                    </li>
                </ul>
    
                <ul className="flex items-center justify-center mt-8 space-x-3 sm:mt-12 lg:justify-end lg:mt-0">
                <li>
                        <a href="https://www.facebook.com/HugloEnergyAUS/" target="_blank" rel="noopener noreferrer" title="" className="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"/>
                            </svg>
                        </a>
                    </li>

                    <li>
                        <a href="https://www.linkedin.com/company/hugloenergyaus/" target="_blank" rel="noopener noreferrer" title="" className="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"/>
                            </svg>
                        </a>
                    </li>

                    <li>
                        <a href="https://www.instagram.com/hugloenergyaus/" target="_blank"  rel="noopener noreferrer"  title="" className="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"/>
                                <circle cx="16.806" cy="7.207" r="1.078"/>
                                <path
                                    d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"
                                />
                            </svg>
                        </a>
                    </li>

                    <li>
                        <a href="https://twitter.com/hugloenergyaus" target="_blank"  rel="noopener noreferrer"  title="" className="inline-flex items-center justify-center w-10 h-10 text-gray-900 transition-all duration-200 rounded-full hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
                            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                    d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"
                                />
                            </svg>
                        </a>
                    </li>

 

                </ul>
            </div>
    
            <hr className="mt-5 border-gray-300" />
    
            <div className="mt-5 md:flex md:items-center md:justify-between">
                <ul className="flex flex-col items-center justify-center space-y-4 md:flex-row md:space-y-0 md:space-x-6 md:order-2 md:justify-end">
                    <li>
                        <a href="https://www.huglo.com.au/terms-conditions" target="_blank" rel="noopener noreferrer"  title="" className="text-sm font-normal text-gray-600 transition-all duration-200 font-pj hover:text-gray-900"> Terms & Conditions </a>
                    </li>
                    
                    <li>
                        <a href="https://www.huglo.com.au/privacy-policy" target="_blank" rel="noopener noreferrer" title="" className="text-sm font-normal text-gray-600 transition-all duration-200 font-pj hover:text-gray-900"> Privacy Policy </a>
                    </li>

                    <li>
                        <a href="https://www.huglo.com.au/privacy-collection-statement" rel="noopener noreferrer" target="_blank"  title="" className="text-sm font-normal text-gray-600 transition-all duration-200 font-pj hover:text-gray-900"> Collection statement</a>
                    </li>
                </ul>
    
                <p className="mt-8 text-xs font-normal text-center text-gray-600 md:text-left md:mt-0 md:order-1 font-pj">
                    © 2024 by Huglo Pty Ltd <br /> 
                    ABN 19 643 419 765 <br /> 
                    Electrical Contractor Licence: <br /> 
                    367203C (NSW), 2022246 (ACT)
                </p>
            </div>
            </div>
        </footer>
    );
};

export default Footer;
